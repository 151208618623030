import { FunctionComponent } from "react";

interface IClientNEATTProps {
    neatt: Record<any, any>
}

const ClientNEATT: FunctionComponent<IClientNEATTProps> = ({neatt}) => {
    console.log(neatt)
    if(!Object.keys(neatt)?.length) return <></>;

    return (
        <>
        <div className="w-full border-primary border-bottom flex justify-between align-center mb-4">
            <h4 className="text-2xl">NEATT Information</h4>
        </div>
        <div className="text-md grid grid-cols-2 gap-2">
            <p className="mb-3"><span className="font-medium">Behind in Child Support:</span> <span className={`${neatt.behindInChildSupport ? 'text-success' : 'text-danger'} font-semibold`}>{`${neatt.behindInChildSupport}`}</span></p>
            <p className="mb-3"><span className="font-medium">Buying Marital Residence:</span> <span className={`${neatt.buyingMaritalResidence ? 'text-success' : 'text-danger'} font-semibold`}>{`${neatt.buyingMaritalResidence}`}</span></p>
            <p className="mb-3"><span className="font-medium">Considering Marriage:</span> <span className={`${neatt.consideringMarriage ? 'text-success' : 'text-danger'} font-semibold`}>{`${neatt.consideringMarriage}`}</span></p>
            <p className="mb-3"><span className="font-medium">Declaring Bankruptcy:</span> <span className={`${neatt.declaringBankruptcy ? 'text-success' : 'text-danger'} font-semibold`}>{`${neatt.declaringBankruptcy}`}</span></p>

            <p className="mb-3"><span className="font-medium">Decreased Work Hours:</span> <span className={`${neatt.decreasedWorkHours ? 'text-success' : 'text-danger'} font-semibold`}>{`${neatt.decreasedWorkHours}`}</span></p>
            <p className="mb-3"><span className="font-medium">Defaulted:</span> <span className={`${neatt.defaulted ? 'text-success' : 'text-danger'} font-semibold`}>{`${neatt.defaulted}`}</span></p>
            <p className="mb-3"><span className="font-medium">Denied Mortgage:</span> <span className={`${neatt.deniedMortgage ? 'text-success' : 'text-danger'} font-semibold`}>{`${neatt.deniedMortgage}`}</span></p>
            <p className="mb-3"><span className="font-medium">Denied Rental:</span> <span className={`${neatt.deniedRental ? 'text-success' : 'text-danger'} font-semibold`}>{`${neatt.deniedRental}`}</span></p>
            <p className="mb-3"><span className="font-medium">Dividing Marital Assess:</span> <span className={`${neatt.dividingMaritalAssets ? 'text-success' : 'text-danger'} font-semibold`}>{`${neatt.dividingMaritalAssets}`}</span></p>
            <p className="mb-3"><span className="font-medium">Home Repairs:</span> <span className={`${neatt.homeRepairs ? 'text-success' : 'text-danger'} font-semibold`}>{`${neatt.homeRepairs}`}</span></p>
           
            <p className="mb-3"><span className="font-medium">Household Member Passed:</span> <span className={`${neatt.householdMemberPassed ? 'text-success' : 'text-danger'} font-semibold`}>{`${neatt.householdMemberPassed}`}</span></p>
            <p className="mb-3"><span className="font-medium">Injury or Illness:</span> <span className={`${neatt.injuryOrIllness ? 'text-success' : 'text-danger'} font-semibold`}>{`${neatt.injuryOrIllness}`}</span></p>
            <p className="mb-3"><span className="font-medium">No Medical Insurance:</span> <span className={`${neatt.noMedicalInsurance ? 'text-success' : 'text-danger'} font-semibold`}>{`${neatt.noMedicalInsurance}`}</span></p>
            <p className="mb-3"><span className="font-medium">Reduced Income:</span> <span className={`${neatt.reducedIncome ? 'text-success' : 'text-danger'} font-semibold`}>{`${neatt.reducedIncome}`}</span></p>
            <p className="mb-3"><span className="font-medium">Separated or Considering Divorce:</span> <span className={`${neatt.separatedOrConsideringDivorce ? 'text-success' : 'text-danger'} font-semibold`}>{`${neatt.separatedOrConsideringDivorce}`}</span></p>
            <p className="mb-3"><span className="font-medium">Spouse Passed:</span> <span className={`${neatt.spousePassed ? 'text-success' : 'text-danger'} font-semibold`}>{`${neatt.spousePassed}`}</span></p>

            <p className="mb-3"><span className="font-medium">Tax Debt:</span> <span className={`${neatt.taxDebt ? 'text-success' : 'text-danger'} font-semibold`}>{`${neatt.taxDebt}`}</span></p>
            <p className="mb-3"><span className="font-medium">Unplanned Expenditures:</span> <span className={`${neatt.unplannedExpenditures ? 'text-success' : 'text-danger'} font-semibold`}>{`${neatt.unplannedExpenditures}`}</span></p>
            <p className="mb-3"><span className="font-medium">Unstable Income:</span> <span className={`${neatt.unstableIncome ? 'text-success' : 'text-danger'} font-semibold`}>{`${neatt.unstableIncome}`}</span></p>
        </div>
    </>
    )
}

export default ClientNEATT;