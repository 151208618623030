import { Amplify } from 'aws-amplify';
import { 
    CognitoIdentityProviderClient, 
    SignUpCommand, 
    SignUpCommandInput, 
    ForgotPasswordCommand,
    ForgotPasswordCommandInput,
    ForgotPasswordCommandOutput,
    AdminDisableUserCommand,
    AdminDisableUserCommandInput,
    AdminEnableUserCommand,
    AdminEnableUserCommandInput,
    ConfirmForgotPasswordCommand,
    ConfirmForgotPasswordCommandInput,
    ConfirmForgotPasswordCommandOutput,
    AdminConfirmSignUpCommand,
    AdminUpdateUserAttributesCommand,
    AdminUpdateUserAttributesCommandInput,
} from '@aws-sdk/client-cognito-identity-provider';
import { 
    signIn, 
    getCurrentUser, 
    signOut, 
    GetCurrentUserOutput, 
    SignInOutput,
} from 'aws-amplify/auth'

const clientId = process.env.REACT_APP_COGNITO_CLIENT;
const poolId = process.env.REACT_APP_COGNITO_POOL;    
const accessKeyId = process.env.REACT_APP_ACCESS_KEY || "";
const secretAccessKey = process.env.REACT_APP_SECRET_KEY || "";
const region = process.env.REACT_APP_REGION || "";

const credentials = {
  accessKeyId,
  secretAccessKey
};

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolClientId: clientId || "",
            userPoolId: poolId || "",
        }
    }
});
console.log({region, credentials});
const cognitoClient = new CognitoIdentityProviderClient({region, credentials});

export const registerInput: SignUpCommandInput = { 
  ClientId: clientId, 
  Username: '', 
  Password: '', 
};

export const registerAuthUser = async (email: string, password: string) => {
    if (!email || !password) return;

    registerInput.Username = email;
    registerInput.Password = password;

    try {
        const signUpCommand = new SignUpCommand(registerInput);
        return await cognitoClient.send(signUpCommand)
    } catch (error) {
        throw error;
    }
}

export const disableInput: AdminDisableUserCommandInput = {
    UserPoolId: poolId, 
    Username: '',
}

export const disableUser = async (email: string) => {
    if (!email) return;

    disableInput.Username = email;

    try {
        const disableCommand = new AdminDisableUserCommand(disableInput);
        await cognitoClient.send(disableCommand);
    } catch (error) {
        throw error;
    }
}

export const enableInput: AdminEnableUserCommandInput = {
    UserPoolId: poolId, 
    Username: '',
}

export const enableUser = async (email: string) => {
    if (!email) return;

    enableInput.Username = email;

    try {
        const enableCommand = new AdminEnableUserCommand(enableInput);
        return await cognitoClient.send(enableCommand);
    } catch (error) {
        throw error;
    }
} 

export const confirmInput: AdminEnableUserCommandInput = {
    UserPoolId: poolId, 
    Username: '',
}

export const confirmUser = async (email: string) => {
    if (!email) return;

    confirmInput.Username = email;

    try {
        const confirmCommand = new AdminConfirmSignUpCommand(confirmInput);
        console.log({confirmInput})
        return await cognitoClient.send(confirmCommand);
    } catch (error) {
        throw error;
    }
} 

export const verifyEmailInput: AdminUpdateUserAttributesCommandInput = {
    UserPoolId: poolId,
    Username: '',
    UserAttributes: [
        {
          Name: "email_verified",
          Value: "true",
        },
    ],
}

export const markEmailAsVerified = async (Username: string) => {
    if(!Username) return;

    verifyEmailInput.Username = Username;

    try {
      const command = new AdminUpdateUserAttributesCommand(verifyEmailInput);
  
      const response = await cognitoClient.send(command);
    } catch (error) {
      console.error("Error marking email as verified:", error);
    }
};

export type Login = (username: string, password: string) => Promise<SignInOutput | null>;

export const login: Login = async (username, password) => {
    if (!username || !password) return null;

    try {
        const result: SignInOutput = await signIn({ username, password });
        return result;
    } catch (error) {
        throw error;
    }
}

export type GetAuthUser = () => Promise<GetCurrentUserOutput>;

export const getAuthUser: GetAuthUser = async () => {
    try {
        const result = await getCurrentUser();
        return result;
    } catch (error) {
        throw error;
    }
}

export const logout = async () => {
    try {
        await signOut();
    } catch (error) {
        throw error;
    }
}

export type ForgotPasswordFunction = (Username: string) => Promise<ForgotPasswordCommandOutput>

export const forgotPassword: ForgotPasswordFunction = async (Username) => {
    try {
        const input: ForgotPasswordCommandInput = {
            ClientId: clientId,
            Username
        }
        const command = new ForgotPasswordCommand(input);

        const result = await cognitoClient.send(command);
   
        return result
    } catch (error) {
        throw error;
    }
}

export type ResetPasswordFunction = (Username: string, Password: string, ConfirmationCode: string) => Promise<ConfirmForgotPasswordCommandOutput>

export const resetPassword: ResetPasswordFunction = async (Username, Password, ConfirmationCode) => {
    try {
        const input: ConfirmForgotPasswordCommandInput = {
            ClientId: clientId,
            Username,
            Password,
            ConfirmationCode
        }
        const command = new ConfirmForgotPasswordCommand(input);

        const result = await cognitoClient.send(command);

        return result
    } catch (error) {
        throw error;
    }
}