import { ReactComponent as ClientsIcon } from '../assets/all-clients-icon.svg';
import { ReactComponent as MembersIcon } from '../assets/experts-icon.svg';
import { ReactComponent as CompaniesIcon } from '../assets/institution-icon.svg';
import { ReactComponent as AllClientAppsIcon } from '../assets/client-apps-icon.svg'
import { ReactComponent as BillingIcon } from '../assets/billing-icon.svg';
import { ReactComponent as AccountIcon } from '../assets/settings-icon.svg';
import { ReactComponent as UploadIcon } from '../assets/upload-icon.svg';
import { ReactComponent as LogoutIcon } from '../assets/logout-icon.svg';
import { ReactComponent as ReferIcon } from '../assets/refer-user-icon.svg';
import { ReactComponent as SupportIcon } from '../assets/support-icon-white.svg';
import { ReactComponent as ChatIcon } from '../assets/chat-icon.svg';

import SideNavigationLink from '../components/sideNavigation/SideNavigationLink';
import SubMenu from '../components/notifications/SubMenu';

export const DASHBOARD_LINKS = {
    CLIENTS: (open: boolean) => <SideNavigationLink 
        url='/dashboard/clients' 
        Icon={ClientsIcon} 
        text='Clients' 
        showText={open} 
    />,
    REQUESTS: (open: boolean) => <SideNavigationLink 
        url='/dashboard/requests' 
        Icon={UploadIcon} 
        text='Requests' 
        showText={open} 
    />,
    CHAT: (open: boolean) => <SideNavigationLink 
        url='/dashboard/chat' 
        Icon={ChatIcon} 
        text='Chat' 
        showText={open} 
    />,
    CLIENT_APPLICATIONS: (open: boolean) => <SideNavigationLink 
        url='/dashboard/clients/:id/applications'
        Icon={AllClientAppsIcon} 
        text='Credit Forms' 
        showText={open} 
    />,
    MEMBERS: (open: boolean) => <SideNavigationLink 
        url='/dashboard/members'
        Icon={MembersIcon} 
        text='Members' 
        showText={open} 
    />,
    COMPANIES: (open: boolean) => <SideNavigationLink 
        url='/dashboard/companies'
        Icon={CompaniesIcon} 
        text='Companies' 
        showText={open} 
    />,
    PARTNERS: (open: boolean) => <SideNavigationLink 
        url='/dashboard/partners'
        Icon={ReferIcon} 
        text='Partners' 
        showText={open} 
    />,
    CVA: (open: boolean) => <SideNavigationLink 
        url='/dashboard/managers'
        Icon={UploadIcon} 
        text='Managers'
        showText={open} 
    />,
    UPLOAD: (open: boolean) => <SideNavigationLink 
        url='/dashboard/upload'
        Icon={UploadIcon} 
        text='Upload' 
        showText={open} 
    />,
    BILLING: (open: boolean) => <SideNavigationLink 
        url='/dashboard/billing'
        Icon={BillingIcon} 
        text='Billing' 
        showText={open} 
    />,
    SUPPORT: (open: boolean) => <SideNavigationLink 
        url='/dashboard/support'
        Icon={SupportIcon} 
        text='Support Tickets'  
        showText={open} 
    />, 
    NOTIFICATIONS:(open: boolean) => <SubMenu 
        showText={open}
    />,
    ACCOUNT: (open: boolean) => <SideNavigationLink 
        url='/dashboard/account'
        Icon={AccountIcon} 
        text='Account'  
        showText={open} 
    />, 
    THRYVE: (open: boolean) => <SideNavigationLink
        url='/dashboard/thryve'
        Icon={AccountIcon}
        text="Thryve"
        showText={open}
    />,
    LOGOUT: (open: boolean, logout: () => void) => <SideNavigationLink 
        url='/'
        Icon={LogoutIcon} 
        text='Logout' 
        showText={open} 
        onClick={logout}
    />,
}