import { FunctionComponent } from "react";
import { ReactComponent as EditIcon } from '../../assets/edit-filled.svg';

interface ICompanyApplicationTileProps {
    toggleForm?: () => void;
    application: Record<any, any>;
}

const CompanyApplicationTile: FunctionComponent<ICompanyApplicationTileProps> = ({application, toggleForm}) => (
    <>
        <div className="w-full border-primary border-bottom flex justify-between align-center mb-4">
            <h4 className="text-2xl">Application Information</h4>
            <p className="mt-2">
                <EditIcon
                    height={25}
                    width={25}
                    onClick={toggleForm}
                    className="cursor-pointer"
                    data-testid='edit-application-icon'
                />
            </p>
        </div>
        <div className="text-md flex flex-col align-center">
            <p data-testid = 'status-text' className={`mb-3 ${application?.metadata?.status === 'APPROVED' ? 'text-success' : application?.metadata?.status === 'DENIED' ? 'text-danger' : '' }`}><span className="font-medium">STATUS:</span> {application?.metadata?.status}</p>
            <p className="mb-3"><span className="font-medium">Applied:</span> {application?.metadata?.createdAt}</p>
            <p className="mb-3"><span className="font-medium">Approved:</span> {application?.metadata?.updatedAt}</p>
        </div>
    </>
);
export default CompanyApplicationTile;