import { FunctionComponent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "./Button";


const CookieConsent: FunctionComponent = () => {
  const [consent, setConsent] = useState<"accepted" | "declined" | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const savedConsent = localStorage.getItem("cookieConsent");
    if (savedConsent === "accepted" || savedConsent === "declined") {
      setConsent(savedConsent as "accepted" | "declined");

      if (savedConsent === "declined") {
        navigate("/declined-cookies");
      }
    }
  }, [navigate]);

  const handleAccept = (): void => {
    localStorage.setItem("cookieConsent", "accepted");
    setConsent("accepted");
  };

  const handleDecline = (): void => {
    localStorage.setItem("cookieConsent", "declined");
    console.log("Navigating to /declined-cookies page")
    navigate("/declined-cookies"); // Redirect immediately
  };

  if (consent === null) {
    return (
        <div className="fixed bottom-5 right-5 bg-white border border-gray-300 rounded-lg shadow-subtle p-4 w-[90%] sm:w-[300px] h-auto flex flex-col items-center text-center z-50">
        <div className="flex flex-col items-center text-center">
          <p className="text-sm text-gray-700 mb-4">
            We use cookies and similar technologies to help personalize content, tailor and measure ads, and provide a
            better experience. By clicking accept, you agree to this, as outlined in our Cookie Policy.{" "}
            <a href="/link-to-policy" className="text-primary underline">{/* link to policy */ }
              Learn More
            </a>
          </p>
          <div className="flex space-x-4">
            <Button
              text="Accept"
              onClick={handleAccept}
              styles="bg-success text-white rounded-md px-4 py-2 hover:bg-success-dark transition"
            />
            <Button
              text="Decline"
              onClick={handleDecline}
              styles="bg-danger text-white rounded-md px-4 py-2 hover:bg-danger-dark transition"
            />
          </div>
        </div>
      </div>
    );
  }

  return null; 
};

export default CookieConsent;

