import { FunctionComponent, useState } from "react"
import DashboardPageContainer from "../components/DashboardPageContainer"
import PageTitle from "../components/PageTitle"
import Papa from 'papaparse';
import { ReactComponent as DocumentUploadIcon } from '../assets/doc-upload-icon.svg';
import Button from "../components/Button";
import { useDashboard } from "../hooks/useDashboard";
import { NotificationThemes } from "../context/DashboardContext";


const Thryve: FunctionComponent = () => {
    const [csvData, setCsvData] = useState<any[]>([]);
    const { updateNotification } = useDashboard();

    const convertToCSV = (array: { name: string; email: string, phone: string, address: string, status: string }[]) => {
        const header = ['Status', 'Name', 'Email', 'Phone', 'Address'];
        const rows = array.map(item => [item.status, item.name, item.email, item.phone, item.address]);
    
        // Combine header and rows into a CSV format string
        const csv = [header, ...rows]
            .map(row => row.join(','))
            .join('\n');
    
        return csv;
    };

    const submitTransfer = async () => {
        try {
            console.log('here')
            const payload = [];

            for(let i = 0; i < csvData.length; i++) {
                payload.push({
                    name: `${csvData[i]?.fName} ${csvData[i]?.lName}`,
                    email: csvData[i]?.email,
                    phone: csvData[i]?.phone,
                    address: csvData[i]?.address,
                    status: csvData[i]?.status
                })
            }

            const csvContent = convertToCSV(payload);

        // Create a Blob from the CSV string
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        // Create an invisible link element to trigger the download
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'transfer_data.csv');

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up by revoking the URL object
        URL.revokeObjectURL(url);
        } catch(error) {
            updateNotification({
                //@ts-ignore
                message: 'Transfer Failed ' + error?.message || '',
                theme: NotificationThemes.FAIL
            })
        }
    }

    const handleFileUpload = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            Papa.parse(file, {
                skipEmptyLines: true,
                complete: (result) => {
                    const filtered = result.data.map((row: any) => {
                        return {
                            fName: row[0]?.trim(), 
                            lName: row[1]?.trim(), 
                            email: row[2]?.trim(), 
                            address: row[4]?.trim(), 
                            status: row[22]?.trim(), 
                            phone: row[38]?.trim()
                        }
                    })?.filter((row: any) => row.status !== "Consulting Client");
                    setCsvData(filtered);
                },
                error: (error) => {
                    console.error("Error parsing CSV file:", error);
                }
            });
        }
    };

    return (
        <DashboardPageContainer>
            <PageTitle title="Thryve" />
            {
                !csvData.length ? (
                    <div className="w-full flex justify-center items-center py-20">  
                        <div>
                            <label htmlFor="file" className="block rounded cursor-pointer w-[250px] h-[50px] bg-secondary-light flex justify-center items-center">
                                <p className="font-medium text-xl leading">Browse Files</p>
                                <DocumentUploadIcon
                                    height={25}
                                    width={25}
                                    className="ml-4"
                                />
                            </label>
                            <input
                                id="file"
                                type="file"
                                accept=".csv"
                                style={{ visibility: "hidden" }}
                                onChange={handleFileUpload}
                            />
                        </div>
                    </div>
                ) : (
                    <div>
                        <div>
                            {
                                csvData.map((item: any) => (
                                    <div className="flex justify-start">
                                        <p className="w-[150px] mr-6 truncate">{item.fName}</p>
                                        <p className="w-[150px] mr-6 truncate">{item.lName}</p>
                                        <p className="w-[150px] mr-6 truncate">{item.email}</p>
                                        <p className="w-[150px] mr-6 truncate">{item.address}</p>
                                        <p className="w-[150px] mr-6 truncate">{item.status}</p>
                                        <p className="w-[150px] mr-6 truncate">{item.phone}</p>
                                    </div>
                                ))
                            }

                            <Button
                                text="Send"
                                onClick={submitTransfer}
                            />
                        </div>
                    </div>
                )
            }
        </DashboardPageContainer>
    )
}

export default Thryve;