import { useState, FunctionComponent, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import { ACCESS_LEVEL, CARD_ELEMENT_OPTIONS, UserTypes } from "../constants/enums";
import { stripeHelpers, userHelpers, companyHelpers } from "../utils/ClientVitalsAPI";
import RadialGradient from "../components/RadialGradient";
import Button from "../components/Button";
import { TextInput } from "../components/inputs";
import { Member } from "../constants/members";
import { Company } from "../constants/companies";

const PaymentInformation: FunctionComponent = () => {
     const [user, setUser] = useState<Company | Member>({
        userType: UserTypes.COMPANY,
        userId: '',
        userInfo: {name: '', address: '', city: '', state: '', zip: '', email: '', country: '', phone: '', companyId: '',},
        metadata: {createdAt: '', updatedAt: '', firstLogin: true, isAdmin: false, accessLevel: ACCESS_LEVEL.CLIENT}
    });
     const [singlePulls, setSinglePulls] = useState<any[]>([]);
     const [triPulls, setTriPulls] = useState<any[]>([]);
     const [accounts, setAccounts] = useState<any[]>([]);
     const [selectedSingle, setSeletedSingle] = useState<string>('');
     const [selectedTri, setSeletedTri] = useState<string>('');
     const [isProcessing, setIsProcessing] = useState<boolean>(false);
     const [error, setError] = useState<any>(null);
     const [discountCode, setDiscountCode] = useState<string>('');
     const [params] = useSearchParams();
     const stripe = useStripe();
     const elements = useElements();
     const navigate = useNavigate()

     const userId = params.get('ui');
     const stripeId = params.get('si');
     const name = params.get('name');

    const fetchUser = async () => {
        if(!userId) return;

        try {
            const user = await userHelpers.getUserById(userId);

            setUser(user);
        } catch(error) {
            console.log(error);
        }
    }

    const fetchAndSortProducts = async () => {
        if(!stripeId) return;

        try {
            const catalogue = await stripeHelpers.fetchStripeCatalogue();
           
            const single = catalogue.data.filter((product: any) => product.name.toLowerCase().includes('single'));
            const tri = catalogue.data.filter((product: any) => product.name.toLowerCase().includes('tri-merge'));
            const accountSubscriptions = catalogue.data.filter((product: any) => product.name.toLowerCase().includes('subscription'));

            setSinglePulls(single);
            setTriPulls(tri);
            setSeletedSingle(single[0].id || '');
            setSeletedTri(tri[0].id || '');
            setAccounts(accountSubscriptions);
        } catch(error: any) {
            console.log(error);
            setError(error?.message);
        }
    }

     const setupPaymentMethod = async () => {
        if (!elements || !stripe || !name) return;
        setIsProcessing(true);
        try {
        const card = elements.getElement(CardElement);
        if (!card) throw new Error('Failed to fetch card');

        const { error: pmError, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card,
            billing_details: { name },
        });

        if (paymentMethod) {
            const intent = await stripeHelpers.setupStripeIntent(stripeId);

            const { error, setupIntent } = await stripe.confirmCardSetup(intent.result.client_secret, {
            payment_method: paymentMethod.id,
            });

            await stripeHelpers.addNewPaymentMethod({ customerId: stripeId, paymentMethodId: paymentMethod.id });

            // Proceed to charge after saving the card
           const purchaseResult = await purchasePrePaidPulls(paymentMethod.id);

           if(purchaseResult.result) {
            //   console.log(purchaseResult)
            //   user.metadata.singlePulls = Number(purchaseResult.selectedSingleProduct?.name?.split(' ')[0] || 0);
            //   user.metadata.triPulls = Number(purchaseResult.selectedTriProduct?.name?.split(' ')[0] || 0);

            user.metadata.subscriptionPaid = true;
            user.metadata.subscriptionDate = new Date();
        
            await companyHelpers.updateCompany(user);
           }
            setIsProcessing(false);
            navigate('/login')
        }
        } catch (error: any) {
            setError(error || 'Failed To Add Payment Method');
            setIsProcessing(false);
        }
     }

     const purchasePrePaidPulls = async (paymentMethodId: string) => {
        if(!paymentMethodId || !stripe || !stripeId) return {result: false};

        try {      
            // Get the prices for selectedSingle and selectedTri
            const selectedSingleProduct = singlePulls.find((p) => p.id === selectedSingle);
            const selectedTriProduct = triPulls.find((p) => p.id === selectedTri);

            const accountSubPrice = (accounts.find(({name}) => {
                let result;
        
                if(user?.userType === UserTypes.COMPANY && name.toLowerCase().includes('company subscription'))
                    result = true;
                else if (user?.userType === UserTypes.MEMBER && name.toLowerCase().includes('personal subscription'))
                    result = true;
                else if (user?.userType === UserTypes.REFERRAL && name.toLowerCase().includes('referral'))
                    result = true;
                else result = false;
                    
                return result;
             })?.prices[0])
  
            const totalAmount =
            //   (selectedSingleProduct?.prices[0]?.unit_amount || 0) +
            //   (selectedTriProduct?.prices[0]?.unit_amount || 0) + 
              (accountSubPrice.unit_amount);
     
            // Create a payment intent for the total amount
                   // Create a subscription for the customer in Stripe
            const subscription = await stripeHelpers.addSubscriber({
                customer: stripeId,
                items: [
                    // { price: selectedSingleProduct?.prices[0]?.id },
                    // { price: selectedTriProduct?.prices[0]?.id },
                    { price: accountSubPrice.id }
                ],
                default_payment_method: paymentMethodId,
                coupon: discountCode,
                expand: ['latest_invoice.payment_intent'],
            });
         
            if (subscription) {
                return {
                    result: true,
                    selectedSingleProduct,
                    selectedTriProduct,
                    subscription,
                };
            }

            if (error) {
              console.log(error.message);
            }

            return {
                result: true,
                selectedSingleProduct,
                selectedTriProduct
            }
          } catch (error: any) {
            console.log(error);
            setError(error?.message);
            return {
                result: false
            }
          }  
     }

     useEffect(() => {
        fetchUser()
        fetchAndSortProducts()
     }, []);

     useEffect(() => {
        
     }, [stripeId])
     
     const accountSubPrice = (accounts.find(({name}) => {
        let result;
        
        if(user?.userType === UserTypes.COMPANY && name.toLowerCase().includes('company subscription'))
            result = true;
        else if (user?.userType === UserTypes.MEMBER && name.toLowerCase().includes('personal subscription'))
            result = true;
        else if (user?.userType === UserTypes.REFERRAL && name.toLowerCase().includes('referral'))
            result = true;
        else result = false;
            
        return result;
     })?.prices[0]?.unit_amount / 100)

     return (
        <div className='relative h-screen overflow-hidden'>
            <RadialGradient top='0' left='10%' height='600px' width='600px' />
            <div className="flex justify-center align-center mt-12">
                <div className={`w-[750px] mr-10 bg-white rounded-lg border-primary border-2`}>
                    <div className='rounded-t-md font-semibold uppercase text-xl h-[75px] py-8 text-white bg-primary flex items-center justify-center'>
                        <h4>
                            Payment & Credits
                        </h4>
                    </div>
                    {/* {
                        error && <p className='text-danger'>{error}</p>
                    } */}

                    <form className='w-full p-8 flex flex-col justify-center align-center'>
                        <div className="pt-10 px-7 mb-4 flex flex-col">
                            <p className="mb-8 text-gray-700 text-lg font-bold">Card Information:</p>
                            <CardElement options={CARD_ELEMENT_OPTIONS} />
                        </div>
                        {/* <p className="mb-4 mt-6 pl-7 text-gray-700 text-lg font-bold">Prepaid Packages:</p>
                        <div className="pb-10 px-7 flex justify-between align-end">
                            <div>
                                <div>
                                    <RadioGroup
                                        options={[...singlePulls.map(({id, name, prices}) => {
                                            return {
                                                id,
                                                label: `\$${(prices[0]?.unit_amount / 100).toFixed(2)} | ${name}`
                                            }
                                        }), {id: '', label: 'Pay Per Pull'}]}
                                        name={'Single Soft Pulls'}
                                        selectedOption={selectedSingle}
                                        onChange={(selectedId: string) => setSeletedSingle(selectedId)}
                                    />
                                </div>
                            </div>
                            <div>
                                <RadioGroup
                                    options={[...triPulls.map(({id, name, prices}) => {
                                        return {
                                            id,
                                            label: `\$${(prices[0]?.unit_amount / 100).toFixed(2)} | ${name}`
                                        }
                                    }), {id: '', label: 'Pay Per Pull'}]}
                                    name={'Tri-Merge Soft Pulls'}
                                    selectedOption={selectedTri}
                                    onChange={(selectedId: string) => setSeletedTri(selectedId)}
                                />
                            </div>
                        </div> */}

                        <div className="p-8">
                            <div>
                                <TextInput 
                                    id="couponCode" 
                                    label="Coupon Code" 
                                    placeholder="Coupon Code..." 
                                    onChange={(value: string) => setDiscountCode(value)}
                                />
                            </div>
                            <div className="flex align-end justify-between mb-4 text-lg">
                                <p className="font-semibold">Account Subscription:</p>
                                <p className="font-normal">${accountSubPrice ? accountSubPrice.toFixed(2) : 'Cannot Find Subscription Type'}</p>
                            </div>
                            {/* <div className="flex align-end justify-between mb-4 text-lg">
                                <p className="font-semibold">Single Soft Credit Pulls:</p>
                                <p className="font-normal">${singlePullPrice.toFixed(2)}</p>
                            </div>
                            <div className="flex align-end justify-between mb-2 pb-2 text-lg border-b border-secondary-dark">
                                <p className="font-semibold">Tri-Merge Soft Credit Pulls:</p>
                                <p className="font-normal">${triPullPrice.toFixed(2)}</p>
                            </div> */}
                            <div className="flex align-end justify-between mb-4 text-lg">
                                <p className="font-semibold">Subtotal:</p>
                                <p className="font-normal">${(accountSubPrice).toFixed(2)}</p>
                            </div>
                        </div>
                        
                        <Button 
                            text={isProcessing ? 'Processing...' : 'Submit Payment'} 
                            type="submit" 
                            disabled={!stripe || isProcessing} 
                            styles="mx-auto mt-8 w-fit px-4"
                            onClick={(e) => {
                                e.preventDefault()
                                setupPaymentMethod()
                            }}
                        />
                    </form>
                </div>  
                {/* <div className={`w-[550px] bg-white rounded-lg border-primary border-2`}>
                    <div className='rounded-t-md font-semibold uppercase text-xl h-[75px] py-8 text-white bg-primary flex items-center justify-center'>
                        <h4>
                            Review and Total
                        </h4>
                    </div>
                    
                    <div className="p-8">
                        <div>
                            <TextInput 
                                id="couponCode" 
                                label="Coupon Code" 
                                placeholder="Coupon Code..." 
                                onChange={(value: string) => setDiscountCode(value)}
                            />
                        </div>
                        <div className="flex align-end justify-between mb-4 text-lg">
                            <p className="font-semibold">Account Subscription:</p>
                            <p className="font-normal">${accountSubPrice ? accountSubPrice.toFixed(2) : 'Cannot Find Subscription Type'}</p>
                        </div>
                        <div className="flex align-end justify-between mb-4 text-lg">
                            <p className="font-semibold">Single Soft Credit Pulls:</p>
                            <p className="font-normal">${singlePullPrice.toFixed(2)}</p>
                        </div>
                        <div className="flex align-end justify-between mb-2 pb-2 text-lg border-b border-secondary-dark">
                            <p className="font-semibold">Tri-Merge Soft Credit Pulls:</p>
                            <p className="font-normal">${triPullPrice.toFixed(2)}</p>
                        </div>
                        <div className="flex align-end justify-between mb-4 text-lg">
                            <p className="font-semibold">Subtotal:</p>
                            <p className="font-normal">${(singlePullPrice + triPullPrice + accountSubPrice).toFixed(2)}</p>
                        </div>
                    </div>
                </div>   */}
            </div>
        </div>
     )
}

export default PaymentInformation;