import { FunctionComponent } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import EditClientSchema from "./ValidationScehma/EditClientSchema";
import { useForm } from "react-hook-form";
import { useDashboard } from "../../hooks/useDashboard";
import { NotificationThemes } from "../../context/DashboardContext";
import { clientHelpers } from "../../utils/ClientVitalsAPI";
import { Client } from "../../constants/clients";
import Button, { BUTTON_THEMES } from "../Button";
import { TextInput } from "../inputs";
import useClients from "../../hooks/useClients";

interface IEditPartnerFormProps {
    toggleForm: () => void;
    partner?: Client;
};

const EditPartnerForm: FunctionComponent<IEditPartnerFormProps> = ({ partner, toggleForm}) => {
    const { updateNotification } = useDashboard();
    const { 
        register, 
        handleSubmit, 
        formState: { errors }
    } = useForm({
        defaultValues: {
            name: partner?.userInfo.name,
            address: partner?.userInfo?.address,
            city: partner?.userInfo?.city,
            state: partner?.userInfo?.state,
            zip: partner?.userInfo?.zip,
            dob: partner?.userInfo?.dob,
            phone: partner?.userInfo.phone,
        },
        resolver: yupResolver(EditClientSchema)
    });


    const submit = async (values: any) => {
        if (!partner) return;

        try {
            partner.userInfo.name = values.name;
            partner.userInfo.address = values.address;
            partner.userInfo.city = values.city;
            partner.userInfo.state = values.state;
            partner.userInfo.zip = values.zip;
            partner.userInfo.dob = values.dob;
            partner.userInfo.phone = values.phone;

            const result = await clientHelpers.updateClient(partner);

            if (result.status === 200) {
                updateNotification({
                    message: 'Update Successful',
                    theme: NotificationThemes.SUCCESS
                });
            }
        } catch (error) {
            updateNotification({
                    message: 'Update Failed',
                    theme: NotificationThemes.FAIL
                });
        } finally {
            toggleForm();
        }
    };

    if(!partner) return <p>Loading</p>;


    return( 
    <>
        <div className="w-full border-primary border border-primary border-t-0 border-l-0 border-r-0 flex justify-between align-center mb-4">
            <h4 className="text-2xl">Edit Partner Information</h4>
            
            <div className="mb-2">
                <Button text="Cancel" onClick={toggleForm} theme={BUTTON_THEMES.INVERSE} styles="mr-4 w-[75px] h-[30px] text-sm tracking-wider"/>
                <Button text="Save" onClick={handleSubmit(submit)} theme={BUTTON_THEMES.PRIMARY} styles={'w-[75px] h-[30px] text-sm tracking-wider'} />    
            </div>
        </div>
        <form className="text-md flex flex-col">
                <TextInput
                    label="Name"
                    id='name'    
                    placeholder={partner.userInfo.name}
                    register={register}  
                    containerStyles="flex align-center mb-3"   
                    labelStyles=" mr-5 text-lg mt-2"  
                    error={errors.name?.message}
                    dataCyId="name-input"     
                />
                
                <p className="mb-3"><span className="font-medium mr-3">Email:</span> {partner?.userInfo?.email}</p>    
            
                <TextInput
                    label="Phone"
                    id='phone'    
                    placeholder={partner.userInfo.phone}
                    register={register}  
                    containerStyles="flex align-center mb-3"   
                    labelStyles=" mr-5 text-lg mt-2" 
                    error={errors.phone?.message}  
                    dataCyId="phone-input"   
                />

                <TextInput
                    label="dob"
                    id='dob'    
                    placeholder={partner.userInfo.dob || ''}
                    register={register}  
                    containerStyles="flex align-center mb-3"   
                    labelStyles=" mr-5 text-lg mt-2"   
                    error={errors.dob?.message}  
                    dataCyId="dob-input"   
                />    

                <TextInput
                    label="Address"
                    id='address'    
                    placeholder={partner.userInfo.name}
                    register={register}  
                    containerStyles="flex align-center mb-3"   
                    labelStyles=" mr-5 text-lg mt-2"  
                    error={errors.address?.message} 
                    dataCyId="address-input"    
                />

                <TextInput
                    label="City"
                    id='city'    
                    placeholder={partner.userInfo.city}
                    register={register}  
                    containerStyles="flex align-center mb-3"   
                    labelStyles=" mr-5 text-lg mt-2" 
                    error={errors.city?.message} 
                    dataCyId="city-input"    
                />

                <TextInput
                    label="State"
                    id='state'    
                    placeholder={partner?.userInfo?.state}
                    register={register}  
                    containerStyles="flex align-center mb-3"   
                    labelStyles=" mr-5 text-lg mt-2"   
                    error={errors.state?.message}  
                    dataCyId="state-input"   
                />    

                <TextInput
                    label="Zip"
                    id='zip'    
                    placeholder={partner?.userInfo?.zip}
                    register={register}  
                    containerStyles="flex align-center mb-3"   
                    labelStyles=" mr-5 text-lg mt-2"   
                    error={errors.zip?.message}  
                    dataCyId="zip-input"   
                />        
                <p className="mb-3"><span className="font-medium">Account Creation:</span> {partner?.metadata?.createdAt}</p>
                <p className="mb-3"><span className="font-medium">Last Update:</span> {partner?.metadata?.updatedAt}</p>
        </form>
        </>
        );
};

export default EditPartnerForm;