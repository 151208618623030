import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from 'react-hook-form';
import { ACCESS_LEVEL, UserTypes } from "../constants";
import PageTitle from "../components/PageTitle";
import { TextInput, Checkbox,  } from "../components/inputs";
import { registerAuthUser, confirmUser, markEmailAsVerified } from "../utils/Cognito";
import axios from "axios";
import { addCreditUser } from "../utils/SoftPullAPI";
import { stripeHelpers, clientHelpers } from "../utils/ClientVitalsAPI";
import convert from 'xml-js';
import Button from "../components/Button";

const ThryveRegister: FunctionComponent = () => {
    const navigate = useNavigate();
    const [terms, setTerms] = useState<boolean>(false);
    const {
         register,
         handleSubmit,
         watch,
         getValues,
         formState: { errors }
    } = useForm ();

    const submit: SubmitHandler<any> = async (data: Record<any, any>) => {
        const { email, password } = data;
        
        try {
            // if (!terms) return;
            data.accountType = UserTypes.CLIENT;

            const result = await registerAuthUser(email, password);
  
            await confirmUser(email);
            await markEmailAsVerified(email);

            const client = {
                userId: result?.UserSub || `${Math.random().toFixed(4)}-${Math.random().toFixed(4)}`.replaceAll('.', 'e'),
                userType: UserTypes.CLIENT,
                userInfo: {
                    name: data.name,
                    gender: undefined,
                    email: data.email,
                    address: data.address,
                    city: data.city,
                    state: data.state,
                    zip: data.zip,
                    phone: data.phone,
                    companyId: "c46854e8-a091-7028-ee6d-6a4feaa9ab34",
                    memberId: "8488f498-60a1-703e-a92a-5eff652b134b",
                },
                metadata: {
                    accessLevel: ACCESS_LEVEL.CLIENT,
                    firstLogin: true,
                    createdAt: new Date(),
                    updateAt: new Date(),
                    agreedToTerms: terms,
                    cus_id: '',
                    stripeId: '',
                },
                neatt: {
                    behindInChildSupport: undefined,
                    buyingMaritalResidence: undefined,
                    causeOfBankruptcy: undefined,
                    consideringMarriage: undefined,
                    declaringBankruptcy: undefined,
                    decreasedWorkHours: undefined,
                    defaulted: undefined,
                    deniedMortgage: undefined,
                    deniedRental: undefined,
                    dividingMaritalAssets: undefined,
                    homeRepairs: undefined,
                    householdMemberPassed: undefined,
                    injuryOrIllness: undefined,
                    madeRedundant: undefined,
                    maritalStatus: undefined,
                    newResident: undefined,
                    noMedicalInsurance: undefined,
                    reducedIncome: undefined,
                    separatedOrConsideringDivorce: undefined,
                    spousePassed: undefined,
                    taxDebt: undefined,
                    unplannedExpenditures: undefined,
                    unstableIncome: undefined,
                }
            }
            console.log(client)
            const ipResult = await axios.get('https://api.ipify.org?format=json');

            const cbcResult = await addCreditUser({
                uid: client.userId,
                name: client.userInfo.name,
                email: client.userInfo.email,
                password: data.password,
                ip: ipResult.data.ip
            });

            const result2 = convert.xml2js(cbcResult, { compact: true });
            const customer = await stripeHelpers.addNewCustomer(client);
            
            //@ts-ignore
            client.metadata.cus_id = result2["XML_INTERFACE"]["CUS_ID"]["_text"];
            client.metadata.stripeId = customer?.data.id;
            //@ts-ignore
            await clientHelpers.addClient(client)

            navigate('/login');
        } catch (error: any) {
            console.log({error});
        }
    };

    return (
        <div className="px-12 py-6">
            <PageTitle title="Complete Registration to Transfer into ClientVitals" />

            <div className="w-full flex flex-wrap justify-between">
            <TextInput
                label='Email'
                id='email'
                placeholder='Contact email...'
                containerStyles='w-[45%]'
                testId='register-company-email-input'
                register={register}
            />
    
    
            <TextInput
                label='Password'
                id='password'
                password
                placeholder='Enter Password...'
                register={register}
                containerStyles='w-[45%]'
                testId='register-company-password-input'
            />
    
            <TextInput
                label='Name'
                id='name'
                placeholder='Company name...'
                register={register}
                key='company'
                containerStyles='w-[45%]'
                testId='register-company-company-input'
            />
    
            <TextInput
                label='Phone'
                id='phone'
                placeholder='Contact phone number...'
                register={register}
                containerStyles='w-[45%]'
                testId='register-company-phone-input'
            />
    
            <TextInput
                label='Address'
                id='address'
                placeholder='Street address...'
                register={register}
                containerStyles='w-[45%]'
                testId='register-company-address-input'
            />
    
            <TextInput
                label='City'
                id='city'
                placeholder='City...'
                register={register}
                containerStyles='w-[45%]'
                testId='register-company-city-input'
            />
    
            <TextInput
                label='State'
                id='state'
                placeholder='State...'
                register={register}
                containerStyles='w-[45%]'
                testId='register-company-state-input'      
            />
    
            <TextInput
                label='Zip'
                id='postal_code'
                placeholder='Postal code...'
                register={register}
                containerStyles='w-[45%]'
                testId='register-company-zip-input'
            />
            </div>
    
            <Checkbox
                labelText='Terms & Conditions'
                checked={terms}
                onChange={(event: any) => setTerms(event?.target?.checked)}
                labelStyles='ml-3'
                containerStyles='my-4'
                terms
            />

            <div className="w-full flex justify-center items-centers">
                <Button text="Submit" onClick={handleSubmit(submit)} />
            </div>
        </div>
    )
}

export default ThryveRegister;