import axios from "axios";
import { ACCESS_LEVEL } from "../../constants";

const BASE_URL = process.env.REACT_APP_CLIENTVITALS_API;

// Sendgrid Helpers
const inviteClient = async (payload: { name: string, email: string, company: string, formLink: string }) => {
    try {
        const result = await axios.post(`${BASE_URL}/mail/invite-client`, payload);

        return result
    } catch (error) {
        throw error;
    }
}

const bulkInviteClient = async (payload: Record<any, any>[]) => {
    try {
        const result = await axios.post(`${BASE_URL}/mail/bulk-invite-client`, payload);

        return result?.data?.report
    } catch (error) {
        throw error;
    }
}

const referClient = async (payload: { name: string, email: string, phone: string, address: string, referrer: string }) => {
    try {
        const result = await axios.post(`${BASE_URL}/mail/refer-client`, payload);

        return result
    } catch (error) {
        throw error;
    }
}

const referExistingClient = async (payload: { client: Record<any, any>, referrer: string }) => {
    try {
        const result = await axios.post(`${BASE_URL}/mail/refer-existing-client`, payload);

        return result
    } catch (error) {
        throw error;
    }
}

const inviteMember = async (payload: { name: string, email: string, company: string, formLink: string }) => {
    try {
        const result = await axios.post(`${BASE_URL}/mail/invite-member`, payload);
        
        return result
    } catch (error) {
        throw error;
    }
}

const bulkInviteMember = async (payload: Record<any, any>[]) => {
    try {
        const result = await axios.post(`${BASE_URL}/mail/bulk-invite-member`, payload);

        return result?.data?.report
    } catch (error) {
        throw error;
    }
}

const inviteCompany = async (payload: { name: string, email: string, invitedBy: string, formLink: string }) => {
    try {
        const result = await axios.post(`${BASE_URL}/mail/invite-company`, payload);
        
        return result
    } catch (error) {
        throw error;
    }
}

const invitePartner = async (payload: { name: string, email: string, invitedBy: string, formLink: string }) => {
    try {
        const result = await axios.post(`${BASE_URL}/mail/invite-partner`, payload);
        
        return result
    } catch (error) {
        throw error;
    }
}

const sendWelcome = async (payload: { name: string, email: string }) => {
    try {
        const result = await axios.post(`${BASE_URL}/mail/approval`, payload);

        return result
    } catch (error) {
        throw error;
    }
}

const sendManagerEmail = async (payload: { name: string, email: string, formLink: string, i: string }, accessLevel: ACCESS_LEVEL) => {
    if (accessLevel !== ACCESS_LEVEL.OWNER) return []; 

    try {
        const link = `${BASE_URL}/mail/managers/${accessLevel}`
       
        const result = await axios.post(link, payload);

        return result
    } catch (error) {
        throw error;
    }
}

const sendCreditApplicationLink = async (payload: { name: string, email: string, formLink: string }) => {
    if (!payload || !payload.name || !payload.email || !payload.formLink) throw Error;

    try {
        const link = `${BASE_URL}/mail/credit/application`
       
        const result = await axios.post(link, payload);

        return result
    } catch (error) {
        throw error;
    }
}

const sendMemberPullEmail = async (payload: { name: string, email: string }) => {
    try {
        const result = await axios.post(`${BASE_URL}/mail/ready`, payload);

        return result
    } catch (error) {
        throw error;
    }
}

const sendFollowup = async (payload: { name: string, email: string }) => {
    try {
        const result = await axios.post(`${BASE_URL}/mail/followup`, payload);

        return result
    } catch(error) {
        throw error;
    }
};

const transferClients = async (payload: Record<any, any>[]) => {
    try {
        const result = await axios.post(`${BASE_URL}/mail/thryve-transfer`, payload);

        return result
    } catch (error) {
        throw error; 
    }
}

export default {
    inviteClient,
    bulkInviteClient,
    referClient,
    referExistingClient,
    inviteMember,
    bulkInviteMember,
    inviteCompany,
    sendWelcome,
    sendManagerEmail,
    sendCreditApplicationLink,
    sendMemberPullEmail,
    invitePartner,
    sendFollowup,
    transferClients
}
