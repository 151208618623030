import { FunctionComponent, useEffect } from "react";
import Button from "../components/Button";
import { useNavigate, Link } from "react-router-dom";


const Neatt: FunctionComponent = () => {
    const navigate = useNavigate();

    useEffect(() => {

    }, []);

    return (
        <>
            {/* <div className="hero_area">
                <div className="container-fluid">
                    <nav className="navbar navbar-expand-lg custom_nav-container ">
                    <a className="navbar-brand" href="index.html">
                        <span>
                        Seotech
                        </span>
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="s-1"> </span>
                        <span className="s-2"> </span>
                        <span className="s-3"> </span>
                    </button>
    
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <div className="d-flex mx-auto flex-column flex-lg-row align-items-center">
                        <ul className="navbar-nav  ">
                            <li className="nav-item ">
                            <a className="nav-link" href="index.html">Home <span className="sr-only">(current)</span></a>
                            </li>
                            <li className="nav-item">
                            <a className="nav-link" href="about.html"> About</a>
                            </li>
                            <li className="nav-item active">
                            <a className="nav-link" href="service.html"> Services </a>
                            </li>
                            <li className="nav-item">
                            <a className="nav-link" href="#contactLink">Contact Us</a>
                            </li>
                        </ul>
                        </div>
                        <div className="quote_btn-container ">
                        <a href="">
                            <img src="images/call.png" alt="" />
                            <span>
                            Call : + 01 1234567890
                            </span>
                        </a>
                        <form className="form-inline">
                            <button className="btn  my-2 my-sm-0 nav_search-btn" type="submit"></button>
                        </form>
                        </div>
                    </div>
                    </nav>
                </div>
            </div> */}
    
            <section className="service_section layout_padding">
                <div className="container-fluid">
                <div className="heading_container">
                    <h2 className="text-lg mb-3">
                        Welcome to NEATT
                    </h2>
                    <h3 className="text-lg font-semibold mb-5">
                        Your resource for Understanding Eligibility!
                    </h3>
                    <p className="mb-3">
                        NEATT, which stands for “Not Eligible At This Time” provides comprehensive client services for individuals referred by subscribing clients utilizing ClientVitals software platform.  NEATT is instrumental in addressing the financial needs of individuals facing barriers to traditional lending, ensuring they receive the support necessary to enhance their financial well-being and achieve their homeownership goals.
                    </p>
                    <p>
                        The primary mission of the NEATT is to empower individuals who are currently ineligible for conventional lending products by offering tailored support and resources.
                    </p>
                </div>
    
                <div className="flex justify-around items-center flex-wrap">
                    <div className="bg-white w-[500px] rounded-xl my-4 mx-2">
                        <div className="flex flex-col justify-around items-center h-full p-4">
                        <img src="images/s-1.png" className="h-[75px] w-[75px] mb-4" alt="" />
                        <div className="detail-box">
                            <h5 className="text-black font-semibold mb-2">
                                Our Mission and Objectives
                            </h5>
                            <p className="text-black mb-2">
                                The primary mission of NEATT is to empower individuals who are currently ineligible for conventional lending products by offering tailored support and resources. Our objectives include:
                            </p>
                            <ol className="text-black list-decimal p-2 m-2">
                                <li className="mb-2">Mitigating Barriers to Borrowing: NEATT actively identifies and works to mitigate specific barriers preventing clients from obtaining Loans or credit.
                                </li>
                                <li className="mb-2">Facilitates Access to Equitable Lending Products: NEATT’s dedicated to connecting clients with sustainable and equitable lending options tailored to their financial situations.
                                </li>
                                <li className="mb-2">Support in Financial Development: NEATT assists clients in building a strong financial foundation through guidance on budgeting, saving strategies, and financial planning in conjunction with CFP, all aimed at fostering long-term financial health.
                                </li>
                                <li className="mb-2">Client Goal Alignment: By collaborating closely with clients to understand their financial aspirations, NEATT tailors its services to help them overcome lending hurdles and achieve their goals, whether that involves purchasing their first home, refinancing, or upgrading/downsizing their current residence.</li>
                            </ol>
                        </div>
                    </div>

                    </div>

                    <div className="bg-white w-[550px] rounded-xl my-4 mx-2">
                        <div className="flex flex-col justify-around items-center h-full p-4">
                        <img src="images/s-2.png" className="h-[75px] w-[75px] mb-4" alt="" />
                        <div className="detail-box">
                            <h5 className="text-black font-semibold mb-2">
                                NEATT FAQ
                            </h5>
                            <p className="text-black mb-2">
                                The primary mission of NEATT is to empower individuals who are currently ineligible for conventional lending products by offering tailored support and resources. Our objectives include:
                            </p>
                            <ol className="text-black list-decimal p-2 m-2">
                                <li className="mb-2">Mitigating Barriers to Borrowing: NEATT actively identifies and works to mitigate specific barriers preventing clients from obtaining Loans or credit.
                                </li>
                                <li className="mb-2">Facilitates Access to Equitable Lending Products: NEATT’s dedicated to connecting clients with sustainable and equitable lending options tailored to their financial situations.
                                </li>
                                <li className="mb-2">Support in Financial Development: NEATT assists clients in building a strong financial foundation through guidance on budgeting, saving strategies, and financial planning in conjunction with CFP, all aimed at fostering long-term financial health.
                                </li>
                                <li className="mb-2">Client Goal Alignment: By collaborating closely with clients to understand their financial aspirations, NEATT tailors its services to help them overcome lending hurdles and achieve their goals, whether that involves purchasing their first home, refinancing, or upgrading/downsizing their current residence.</li>
                            </ol>
                        </div>
                    </div>

                    </div>

                    <div className="bg-white w-[550px] rounded-xl my-4 mx-2">
                        <div className="flex flex-col justify-around items-center h-full p-4">
                        <img src="images/s-3.png" className="h-[75px] w-[75px] mb-4" alt="" />
                        <div className="detail-box">
                            <h5 className="text-black font-semibold mb-2">
                                NEW CLIENT REGISTRATION
                            </h5>
                            <Button text="Register Now" onClick={() => navigate('/register-client')} styles="w-[300px] mt-4" />
                        </div>

                        <div className="detail-box">
                            <h5 className="text-black font-semibold mb-2 mt-10">
                                LOGIN
                            </h5>
                            <Button text="Register Now" onClick={() => navigate('/login')} styles="w-[300px] mt-4" />
                        </div>
                    </div>

                    </div>

                    <div className="bg-white w-[500px] rounded-xl my-4 mx-2">
                        <div className="flex flex-col justify-around items-center h-full p-4">
                        <img src="images/s-4.png" className="h-[75px] w-[75px] mb-4" alt="" />
                        <div className="detail-box">
                            <h5 className="text-black font-semibold mb-2">
                                Open House Events
                            </h5>
                            <p className="text-black mb-2">
                                A feature of NEATT  is its monthly open house events, held in collaboration with local realtors. These events are designed to prepare clients who are first-time homebuyers, those looking to refinance, and individuals interested in upgrading or downsizing their current homes. These sessions aim to address the unique challenges these groups face, particularly those stemming from hardships and barriers to traditional lending. Key components of the open house events include:
                            </p>
                            <ol className="text-black list-decimal p-2 m-2">
                                <li className="mb-2">
                                    Educational Workshops led by industry experts focused on critical topics.
                                </li>
                                <li className="mb-2">
                                    Networking Opportunities to connect with realtors who specialize in various market segments, allowing attendees to ask questions and gain insights in the current housing market.
                                </li>
                                <li className="mb-2">
                                    Personalized Consultations with one of our Client Service Managers during the open houses. Providing individualized assessments to their financial situation.
                                </li>
                                <li className="mb-2">
                                    Educational material and resources to demystify the lending process, enhance financial literacy.
                                </li>
                            </ol>
                        </div>
                    </div>

                    </div>

                    <div className="bg-white w-[500px] rounded-xl my-4 mx-2">
                        <div className="flex flex-col justify-around items-center h-full p-4">
                        <img src="images/s-5.png" className="h-[75px] w-[75px] mb-4" alt="" />
                        <div className="detail-box">
                            <h5 className="text-black font-semibold mb-2">
                                Learning Materials
                            </h5>
                            <p className="text-black mb-2 font-bold">
                                CREDIT 101
                            </p>
                            
                            <div>
                                <h5 className="font-semibold text-lg text-black mt-5 mb-3">
                                    What is Credit?
                                </h5>

                                <p className="text-black text-left">
                                📊 <b>Credit 101:</b> Credit is the ability to borrow money or access goods/services with the promise to pay later. Building good credit means you can borrow at better rates and save money. Start building today!
                                </p>

                                <h5 className="font-semibold text-lg text-black mt-5 mb-3">
                                    Why Your Credit Score Matters
                                </h5>

                                <p className="text-black text-left">
                                🌟 <b>Your Credit Score</b> can impact everything from loan approval to job opportunities. A good score (700+) opens doors, while a low score can limit options. Are you checking yours regularly?
                                </p>

                                <h5 className="font-semibold text-lg text-black mt-5 mb-3">
                                    The Three Credit Bureaus
                                </h5>

                                <p className="text-black text-left">
                                <b>Did you know?</b> 📊 There are three major credit bureaus: Equifax, Experian, and TransUnion. They keep track of your credit history, and each may have slightly different reports. Make sure to check all three for accuracy! 
                                </p>

                                
                                <h5 className="font-semibold text-lg text-black mt-5 mb-3">
                                    Types of Credit
                                </h5>

                                <p className="text-black text-left">
                                💳 <b>Credit Types 101</b> <br />
                                    1. Revolving Credit (e.g., credit cards)  <br />
                                    2. Installment Loans (e.g., car loans, student loans)  <br />
                                    3. Open Credit (e.g., utility bills)  <br />
                                    Understanding these helps you manage your finances better! 💡
                                </p>

                                <h5 className="font-semibold text-lg text-black mt-5 mb-3">
                                    Building Credit from Scratch
                                </h5>

                                <p className="text-black text-left">
                                💳 🚀 <b>New to credit?</b> Start small! Get a secured credit card, make timely payments, and keep your credit utilization low. Slowly, you’ll build a strong credit history! 📅
                                </p>

                                <h5 className="font-semibold text-lg text-black mt-5 mb-3">
                                    The Impact of Late Payments
                                </h5>

                                <p className="text-black text-left">
                                    ⏰ <b>Late Payments</b> can hurt your credit score significantly! Paying bills on time is one of the most important steps to maintaining good credit. 📉 Set reminders or automate payments to stay on track!
                                </p>

                                <h5 className="font-semibold text-lg text-black mt-5 mb-3">
                                    What Affects Your Credit Score?
                                </h5>

                                <p className="text-black text-left">
                                    1. Payment History (35%)  <br />
                                    2. Amounts Owed (30%)  <br />
                                    3. Length of Credit History (15%)  <br />
                                    4. New Credit (10%)  <br />
                                    5. Types of Credit (10%)  <br />
                                    Focus on the big factors to boost your score! 📊
                                </p>
                            </div>
                        </div>
                    </div>

                    </div>
                </div>
                </div>
            </section>
    
            <div className="footer_bg">
                <section className="contact_section layout_padding" id="contactLink">
                <div className="container">
                    <div className="heading_container">
                    <h2>
                        Get In touch
                    </h2>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                    <div className="col-lg-6 col-md-8 mx-auto">
                        <form>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                            <input type="text" className="form-control" id="inputName4" placeholder="Name " />
                            </div>
                            <div className="form-group col-md-6">
                            <input type="email" className="form-control" id="inputEmail4" placeholder="Email id" />
                            </div>
    
                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                            <input type="text" className="form-control" id="inputSubject4" placeholder="Subject" />
                            </div>
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" id="inputMessage" placeholder="Message" />
                        </div>
                        <div className="d-flex justify-content-center">
                            <button type="submit" className="">Send</button>
                        </div>
                        </form>
                    </div>
                    </div>
                </div>
                </section>
    
                <section className="info_section layout_padding2">
                <div className="container">
                    <div className="row">
                    <div className="col-md-3">
                        <div className="info_logo">
                        <h3>
                            ClientVitals
                        </h3>
                        {/* <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor indidunt ut labore et
                            dolore magna
                        </p> */}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="info_links">
                        <h4>
                            BASIC LINKS
                        </h4>
                        <ul className="  ">
                            <li className="">
                            <Link className="" to="/">Home <span className="sr-only">(current)</span></Link>
                            </li>
                            <li className="active">
                            <Link className="" to="/neatt">NEATT <span className="sr-only">(current)</span></Link>
                            </li>
                            <li className="">
                            <Link className="" to="/prices"> Prices</Link>
                            </li>
                            <li className="">
                            <Link className="" to='/login'> Login </Link>
                            </li>
                            <li className="">
                            <a className="" href="#contactLink">Contact Us</a>
                        </li>
                        </ul>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="info_contact">
                        <h4>
                            CONTACT DETAILS
                        </h4>
                        <a href="">
                            <div className="img-box">
                            <img src="images/telephone-white.png" width="12px" alt="" />
                            </div>
                            <p>
                            802-922-6268
                            </p>
                        </a>
                        <a href="">
                            <div className="img-box">
                            <img src="images/envelope-white.png" width="18px" alt="" />
                            </div>
                            <p>
                              info@clientvitals.com
                            </p>
                        </a>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="info_form ">
                        {/* <h4>
                            NEWSLETTER
                        </h4>
                        <form action="">
                            <input type="email" placeholder="Enter your email" />
                            <button>
                            Subscribe
                            </button>
                        </form> */}
                        {/* <div className="social_box">
                            <a href="">
                            <img src="images/info-fb.png" alt="" />
                            </a>
                            <a href="">
                            <img src="images/info-twitter.png" alt="" />
                            </a>
                            <a href="">
                            <img src="images/info-linkedin.png" alt="" />
                            </a>
                            <a href="">
                            <img src="images/info-youtube.png" alt="" />
                            </a>
                        </div> */}
                        </div>
                    </div>
                    </div>
                </div>
                </section>
    
                <section className="container-fluid footer_section">
                <div className="container">
                    <p>
                    &copy; <span id="displayYear"></span> All Rights Reserved By
                    ClientVitals Solutions
                    </p>
                </div>
                </section>
            </div>
        </>
    );
}

export default Neatt;