import { useUser } from "../hooks/useUser";
import { UserTypes } from "../constants";
import { FunctionComponent, useState } from "react";
import DashboardPageContainer from "../components/DashboardPageContainer";
import SquareTile from "../components/tiles/SquareTile";
import PageTitle from "../components/PageTitle";
import ResetCBCPassword from "../components/forms/ResetCBCPassword";
import SetScoreThreshold from "../components/inputs/SetScoreThreshold";

const Account: FunctionComponent = () => {
     const [isConfigView, setIsConfigView] = useState<boolean>(false);
     const { user } = useUser();

     return (
          <DashboardPageContainer>
               <PageTitle title="Account" />

               <SquareTile>
                    <div className="w-full border-primary border border-primary border-t-0 border-l-0 border-r-0 flex justify-between align-center mb-4">
                         <div className="flex">
                              <div
                                   onClick={() => setIsConfigView(false)}
                                   className={`cursor-pointer border border-primary py-2 px-4 ${!isConfigView ? 'bg-primary text-white' : 'bg-white text-primary'}`}
                              >
                                   Account
                              </div>
                              <div
                                   onClick={() => setIsConfigView(true)}
                                   className={`cursor-pointer border border-primary py-2 px-4 ${isConfigView ? 'bg-primary text-white' : 'bg-white text-primary'}`}
                              >
                                   Config
                              </div>
                         </div>
                    </div>
                    {isConfigView ? (
                         <div>
                              <SetScoreThreshold />
                              <ResetCBCPassword />
                         </div>
                    ) : (
                         <div>
                              <p className="mb-2">
                                   <span className="font-semibold">Account Holder:</span> {user.userInfo.name}
                              </p>
                              <p className="mb-2">
                                   <span className="font-semibold">Email:</span> {user.userInfo.email}
                              </p>
                              <p className="mb-2">
                                   <span className="font-semibold mr-1">Subscription:</span> 
                                   {
                                        user.userType !== UserTypes.MEMBER ? user.userType 
                                        //@ts-ignore
                                        : user.userInfo?.companyId === process.env.REACT_APP_SUB_ACCOUNT ? "Personal"
                                        : "Member"
                                   }
                              </p>
                         </div>
                    )}
               </SquareTile>
          </DashboardPageContainer>
     );
};

export default Account;