import { FunctionComponent } from 'react';
import { Link } from "react-router-dom";
import CookieConsent from '../components/CookieConsent';

const Landing: FunctionComponent = () => (
  <div className="">
    <CookieConsent />
    <div className="hero_area">
      <header className="header_section">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg custom_nav-container ">
            <Link className="navbar-brand ml-[8.5rem]" to="/">
              <span>
                ClientVitals
              </span>
            </Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="s-1"> </span>
            <span className="s-2"> </span>
            <span className="s-3"> </span>
          </button>

          <div className=" navbar-collapse" id="navbarSupportedContent">
            <div className="d-flex mx-auto flex-column flex-lg-row align-items-center">
              <ul className="navbar-nav z-50">
                <li className="nav-item active">
                  <Link className="nav-link" to="/">Home <span className="sr-only">(current)</span></Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/prices"> Prices</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/neatt"> NEATT</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/login"> Login </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#contactLink">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>

    <section className=" slider_section ">
      <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="container">
              <div className="row max-w-[80%] mx-auto">
                <div className="col-md-6 ">
                  <div className="detail_box">
                    <h1>
                      Prequalification made easy
                    </h1>
                    <p>
                      Use <b>no-impact</b> FICO real-time soft pulls to identify barriers to lending when onboarding. 
                    </p>
                    <div className="btn-box">
                      <a href="#contactLink" className="btn-1">
                        Contact Us
                      </a>
                      <a href="" className="btn-2">
                        Schedule a Demo
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="img-box">
                    <img src="images/slider-img.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item ">
            <div className="container">
              <div className="row max-w-[80%] mx-auto">
                <div className="col-md-6 ">
                  <div className="detail_box">
                    <h1>
                      Client Management Simplified
                    </h1>
                    <p>
                      Help ineligible clients become eligible by mitigating any barriers
                    </p>
                    <div className="btn-box">
                      <Link to="/register" className="btn-1">
                        Register
                      </Link>
                      <Link to="/prices" className="btn-2">
                        Pricing
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="img-box">
                    <img src="images/slider-img.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item ">
            <div className="container">
              <div className="row max-w-[80%] mx-auto">
                <div className="col-md-6 ">
                  <div className="detail_box">
                    <h1>
                      Achieve Your Financial Goals
                    </h1>
                    <p>
                      Why rely on guesswork when you can have certainty? Get in-depth insights and assistance today.
                    </p>
                    <div className="btn-box">
                      <Link to="/register-client" className="btn-1">
                        Client Registration
                      </Link>
                      <a href="" className="btn-2">
                        Book Appointment
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="img-box">
                    <img src="images/slider-img.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel_btn-container">
          <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    </section>
  </div>

  <section className="about_section ">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="img-box">
            <img src="images/about-img2.png" alt="" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="detail-box">
            <div className="heading_container">
              <h2>
                About Us
              </h2>
            </div>
            <p>
              <b>23% of all credit applicants are rejected.</b> Money is not being lent, often for reasons that can easily be mitigated. ClientVitals provides financial services that aim to decrease the rejection rate, increase the number, volume and quality of loans, within the context of responsible lending and borrowing. 
            </p>

            <p>
              For finance professionals, seeing real-time accurate credit information while onboarding means decisions will better reflect people’s actual (true) underlying financial circumstances. This should ensure that consumers are not denied credit they could afford or given credit they can’t afford. 
            </p>

            <p>
              Adverse information seen later in the process can materially change the advice given to a client or the client’s eligibility. Examples of typical undisclosed information are student loan payments, alimony, child support, tax liens, previous bankruptcies, foreclosures, repossessions, court Judgments and guarantees for someone else. 
            </p>

            <p>
              Finance professionals will therefore save time, onboard within minutes, increase both the number and quality of deals, with higher retention and customer loyalty.  Their clients will have more offers, fewer rejections, and access to the best loan terms.  
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className="service_section layout_padding">
    <div className="container-fluid">
      <div className="heading_container">
        <h2>
          Our Services
        </h2>
        <p className='font-semibold mt-2'>
          Real-time, 24 hour Soft Pull Dashboard 
        </p>
      </div>

      <div className="service_container">
        <div className="box">
          <div className="img-box">
            <img src="images/s-1.png" alt="" />
          </div>
          <div className="detail-box">
            <h5>
              Full Bureau Access
            </h5>
            <p className='mt-3'>
              Search 3 credit bureaus and Office of Foreign Assets Control
            </p>
          </div>
        </div>
        <div className="box">
          <div className="img-box">
            <img src="images/s-2.png" alt="" />
          </div>
          <div className="detail-box">
            <h5>
              User-friendly interface
            </h5>
            <p className='mt-3'>
              Software embedded in third party systems to enable easy access to soft pull credit reports
            </p>
          </div>
        </div>
        <div className="box">
          <div className="img-box">
            <img src="images/s-3.png" alt="" />
          </div>
          <div className="detail-box">
            <h5>
              Differential Report
            </h5>
            <p className='mt-3'>
              Real-time differential report highlighting credit bureau differences, lending barriers and actions to be taken
            </p>
          </div>
        </div>
        <div className="box">
          <div className="img-box">
            <img src="images/s-4.png" alt="" />
          </div>
          <div className="detail-box">
            <h5>
              Client Referrals
            </h5>
            <p className='mt-3'>
              Refer clients to our expert team for prequalification mitigation assistance
            </p>
          </div>
        </div>
        <div className="box">
          <div className="img-box">
            <img src="images/s-5.png" alt="" />
          </div>
          <div className="detail-box">
            <h5>
              Prequalification Mitigation
            </h5>
            <p className='mt-3'>
              Expert services in financial hardship mitigation (e.g. marital, medical, employment), lending risk management and prequalification insight.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className="work_section layout_padding mx-20">
    <div className="container">
      <div className="heading_container">
        <h2>
          How We Work!
        </h2>
        <p>
          Get started in minutes
        </p>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="detail_container">
            <div className="box b-1">
              <div className="top-box">
                <div className="icon-box">
                  <img src="images/work-i1.png" alt="" />
                </div>
                <h5>
                  Step 1
                </h5>
              </div>
              <div className="bottom-box">
                <p>
                  Register by completing our compliance questionnaire
                </p>
              </div>
            </div>
            <div className="box b-1">
              <div className="top-box">
                <div className="icon-box">
                  <img src="images/work-i2.png" alt="" />
                </div>
                <h5>
                  Step 2
                </h5>
              </div>
              <div className="bottom-box">
                <p>
                  Select which service fits your needs
                </p>
              </div>
            </div>
            <div className="box b-1">
              <div className="top-box">
                <div className="icon-box">
                  <img src="images/work-i2.png" alt="" />
                </div>
                <h5>
                  Step 3
                </h5>
              </div>
              <div className="bottom-box">
                <p>
                  Pay & get started right away
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className="team_section layout_padding2-bottom">
    <div className="container">
      <div className="heading_container">
        <h2>
          Meet The Team
        </h2>
      </div>
    </div>
    <div className="team_container">
      <div className="box b-1">
        <div className="detail-box">
          <h5>
            Jonathan Goffe - Founder
          </h5>
          <p className='mt-8'>
            10 years as a Housing Urban Department counsellor. 15 years as a specialized social worker, focussing on the underbanked and unbanked populations in Vermont. Jonathan understands credit inside-out. Proven track record of debt negotiation, foreclosure mitigation, removal of lending barriers and enhancing financial literacy. He has personally negotiated cancellation of millions of overdue debt, enabling access to better loan terms.
          </p>
        </div>
      </div>

      <div className="box b-1">
        <div className="detail-box">
          <h5>
            Tim Carpenter - Advisor
          </h5>
          <p className='mt-8'>
            Vice President, Commercial Banking, Northfield Savings Bank. Previously Senior Lending Manager at Opportunities Credit Union.
          </p>
        </div>
      </div>

      <div className="box b-1">
        <div className="detail-box">
          <h5>
            Ryan White - Advisor
          </h5>
          <p className='mt-8'>
            Retired ex Managing Director and Head of Loan Trading for The Bank of Nova Scotia in New York. His career at JPMorgan Chase, Deutsche Bank and CIBC involved many transactions for small, mid and large cap companies.
          </p>
        </div>
      </div>

      <div className="box b-1">
        <div className="detail-box">
          <h5>
            Mike Joseph - Advisor
          </h5>
          <p className='mt-8'>
            Business Coach who helps business owners increase revenues and profits by applying strategies outlined in his book “Pathway to Profits: Unlocking your business”.
          </p>
        </div>
      </div>

      <div className="box b-1">
        <div className="detail-box">
          <h5>
            Jude Goffe - Advisor
          </h5>
          <p className='mt-8'>
            Semi retired after 40 years in private equity in London, focussed on early stage companies. UK Government non-executive appointee as regulator of commercial television and the National Health Service. 
          </p>
        </div>
      </div>

      <div className="box b-1">
        <div className="detail-box">
          <h5>
            Jodi Harrington - Advisor
          </h5>
          <p className='mt-8'>
            20+ years in financial education and marketing at Opportunities Credit Union and Coop, focussed on diverse communities. 9 years as a small business advisor, helping micro businesses. 
          </p>
        </div>
      </div>
    </div>
  </section>

  <section className="client_section layout_padding-bottom">
    <div className="container">
      <div className="heading_container">
        <h2>
          Testimonial
        </h2>
      </div>
    </div>
    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="container">
            <div className="box">
              <div className="detail-box">
                <h6>
                  Normal distribution
                </h6>
                <p>
                  It is a long established fact that a reader will be distracted by the readable content of a page when
                  looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
                  distribution of
                  letters, as opposed to using 'Content here, content here', making it look
                </p>
                <img src="images/quote.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <div className="container">
            <div className="box">
              <div className="detail-box">
                <h6>
                  Normal distribution
                </h6>
                <p>
                  It is a long established fact that a reader will be distracted by the readable content of a page when
                  looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
                  distribution of
                  letters, as opposed to using 'Content here, content here', making it look
                </p>
                <img src="images/quote.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <div className="container">
            <div className="box">
              <div className="detail-box">
                <h6>
                  Normal distribution
                </h6>
                <p>
                  It is a long established fact that a reader will be distracted by the readable content of a page when
                  looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
                  distribution of
                  letters, as opposed to using 'Content here, content here', making it look
                </p>
                <img src="images/quote.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="carousel_btn-container">
        <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
          <span className="sr-only">Next</span>
        </a>
      </div>
    </div>

  </section>

  <div className="footer_bg">
    <section className="contact_section layout_padding" id="contactLink">
      <div className="container">
        <div className="heading_container">
          <h2>
            Get In touch
          </h2>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-8 mx-auto">
            <form>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <input type="text" className="form-control" id="inputName4" placeholder="Name " />
                </div>
                <div className="form-group col-md-6">
                  <input type="email" className="form-control" id="inputEmail4" placeholder="Email" />
                </div>

              </div>
              <div className="form-row">
                <div className="form-group col">
                  <input type="text" className="form-control" id="inputSubject4" placeholder="Subject" />
                </div>
              </div>
              <div className="form-group">
                <input type="text" className="form-control" id="inputMessage" placeholder="Message" />
              </div>
              <div className="d-flex justify-content-center">
                <button type="submit" className="">Send</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <section className="info_section layout_padding2">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="info_logo">
              <h3>
                ClientVitals
              </h3>
            </div>
          </div>
          <div className="col-md-3">
            <div className="info_links">
              <h4>
                BASIC LINKS
              </h4>
              <ul className="  ">
                <li className=" active">
                  <Link className="" to="/">Home <span className="sr-only">(current)</span></Link>
                </li>
                <li className="">
                            <Link className="" to="/neatt">NEATT <span className="sr-only">(current)</span></Link>
                            </li>
                <li className="">
                  <Link className="" to="/prices"> Prices</Link>
                </li>
                <li className="">
                  <Link className="" to='/login'> Login </Link>
                </li>
                <li className="">
                  <a className="" href="#contactLink">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="info_contact">
              <h4>
                CONTACT DETAILS
              </h4>
              <a href="">
                <div className="img-box">
                  <img src="images/telephone-white.png" width="12px" alt="" />
                </div>
                <p>
                  802-922-6268
                </p>
              </a>
              <a href="">
                <div className="img-box">
                  <img src="images/envelope-white.png" width="18px" alt="" />
                </div>
                <p>
                  info@clientvitals.com
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="container-fluid footer_section">
      <div className="container">
        <p>
          &copy; <span id="displayYear"></span> ClientVitals 2024
        </p>
      </div>
    </section>
  </div>

  </div>
);

export default Landing;
