import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import Button from "../components/Button";


const Prices: FunctionComponent = () => {

    return (
        <div className="flex flex-wrap justify-around items-center h-screen">
            <div className='w-[500px] h-[575px] bg-white rounded-lg border-primary border-2'>
                <div className='rounded-t-md font-semibold uppercase text-xl h-[75px] py-8 text-white bg-primary flex items-center justify-center'>
                    <h4>
                        Subscriber - $200
                    </h4>
                </div>
         
                <div className='py-4 px-8 flex flex-col justify-between items-center h-[500px]'>
                    <p className="mt-6 mb-10">
                        Available in both personal accounts and company accounts, this subscription gives you full access to both ClientVitals software, as well as the NEATT department for client prequalification mitigation. Best if you are looking for data insight, soft credit pulls, or managing a team and their corresponding clients. 
                    </p>

                    <h5 className="font-semibold uppercase text-xl mb-4">Features:</h5>

                    <ul className="pl-8 list-disc">
                        <li>Full Client Management</li>
                        <li>Soft Credit Pulls</li>
                        <li>Differential Report on Tri-Merge Soft Pulls</li>
                        <li>Client Referrals to NEATT Department</li>
                        <li>Multiple Users for Company Accounts</li>
                    </ul>

                    <Link to="/register" className="mx-auto mt-4">
                        <Button text="Register" />
                    </Link>
                </div>
            </div> 

            <div className='w-[500px] h-[575px] bg-white rounded-lg border-primary border-2'>
                <div className='rounded-t-md font-semibold uppercase text-xl h-[75px] py-8 text-white bg-primary flex items-center justify-center'>
                    <h4>
                        Partner - $0
                    </h4>
                </div>
         
                <div className='py-4 px-8 flex flex-col justify-between items-center h-[500px]'>
                    <p className="mt-6 mb-10">
                        Account for individuals who wish to utilize the NEATT department's mitagation services without doing soft credit pulls themselves. Handle your clients your way, and only involve us when you decide it is best. Client lists and referrals are managed in our simple to use dashboard. 
                    </p>

                    <h5 className="font-semibold uppercase text-xl mb-4">Features:</h5>

                    <ul className="pl-8 list-disc">
                        <li>Full Client Management</li>
                        <li>Client Referrals to NEATT Department</li>
                    </ul>

                    <Link to="/register-referral" className="mx-auto mt-4">
                        <Button text="Register" />
                    </Link>
                </div>
            </div> 

            <div className='w-[500px] h-[575px] bg-white rounded-lg border-primary border-2'>
                <div className='rounded-t-md font-semibold uppercase text-xl h-[75px] py-8 text-white bg-primary flex items-center justify-center'>
                    <h4>
                        Client - $0
                    </h4>
                </div>
         
                <div className='py-4 px-8 flex flex-col justify-between items-center h-[500px]'>
                    <p className="mt-6 mb-10">
                        Whether you are working with a financial professional or just looking for better ways to manage your finance goals yourself, this is the account for you. After a short questionnaire, you will be able to book an intake & assessment call* with one of our NEATT experts to get you on the right road. 
                    </p>

                    <h5 className="font-semibold uppercase text-xl mb-4">Features:</h5>

                    <ul className="pl-8 list-disc">
                        <li>Access to NEATT experts through the dashboard</li>
                        <li>One-on-one appointments with NEATT experts</li>
                    </ul>

                    <p className="my-6">*Intake & Assessment calls require payment of $200 before booking</p>

                    <Link to="/register-client" className="mx-auto mt-4">
                        <Button text="Register" />
                    </Link>
                </div>
            </div> 
        </div>
    )
}

export default Prices;