import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { FunctionComponent, useState } from "react";
import DashboardPageContainer from "../../components/DashboardPageContainer";
import PageTitle from "../../components/PageTitle";
import useClients from "../../hooks/useClients";
import SquareTile from "../../components/tiles/SquareTile";
import ClientInfoTile from "../../components/clients/ClientInfoTile";
import EditClientForm from "../../components/forms/EditClientForm";
import ClientNotesFeature from "../../components/clients/ClientNotes";
import Loading from "../../components/Loading";
import ReferClientModal from "../../components/modals/ReferClientModal";
import { creditHelpers } from "../../utils/ClientVitalsAPI";
import Messenger from "../../components/messenger";
import { useUser } from "../../hooks/useUser";
import ScheduleFollowUp from "../../components/ScheduleFollowUp";
import ClientNEATT from "../../components/clients/ClientNEATT";

const ClientId: FunctionComponent = () => {
    const [showForm, setShowForm] = useState<boolean>(false);
    const [clientApps, setClientApps] = useState<any[]>([]);
    const { user } = useUser();
    const { getClientById, loading } = useClients();
    const { pathname } = useLocation();

    const pathArray = pathname.split('/');
    const clientId = pathArray[pathArray.length - 1];
    const client = getClientById(clientId);

    const toggleForm = () => setShowForm(!showForm)

    useEffect(() => {
        const fetch = async () => {
            try {
                if (!client) return;
                const result = await creditHelpers.fetchAppsByClient(client?.userInfo.email);

                setClientApps(result);
            } catch (error) {
                console.log(error)
            }
        }
        if (client) fetch()
    }, [client]);


    return (
        <DashboardPageContainer>
            <PageTitle 
                title={`${client?.userInfo.name}`} 
                ActionButton={() => (
                    <div className="flex">
                        <ReferClientModal existingClient={client} />
                        {user?.metadata?.accessLevel <= 1 && (
                            <ScheduleFollowUp 
                                id={clientId}
                                name={client?.userInfo?.name || ""}
                                email={client?.userInfo?.email || ""}
                            />
                        )}
                    </div>
                )} 
            />

            <div className="grid grid-cols-2 gap-20">
                <SquareTile>
                    {
                        (loading || !client) ? <Loading />
                            : (client && !showForm) ? <ClientInfoTile toggleForm={toggleForm} client={client} />
                                : <EditClientForm toggleForm={toggleForm} client={client} />

                    }
                </SquareTile>

                <SquareTile>
                    {
                        //@ts-ignore
                        (loading || !client) ? <Loading /> : <ClientNEATT neatt={client?.neatt || {}} />
                    }
                </SquareTile>
            </div>
            <div className="grid grid-cols-2 gap-20 mt-20">
                <SquareTile>
                    {
                        (loading || !client) ? <Loading />
                            : (client && !showForm) ? <ClientNotesFeature toggleForm={toggleForm} client={client} /> : true
                                
                    }
                </SquareTile>
                <SquareTile>
                    {
                        loading ? <Loading />
                            : (
                                <div>
                                    <div className="w-full border-primary border-bottom border-primary flex justify-between align-center mb-4">
                                        <h4 className="text-2xl">Credit Applications</h4>
                                        <Link to={`/dashboard/clients/${clientId}/applications`} className="mt-2 text-primary font-semibold cursor-pointer hover:underline">
                                            View All
                                        </Link>
                                    </div>

                                    <div>
                                        {
                                            clientApps?.map(app => (
                                                <Link to={`/dashboard/clients/${clientId}/applications/${app.applicationId}`}>
                                                <div className="flex items-center justify-around border-bottom border-primary mb-3">
                                                    <p><span className="font-medium">Consent Given:</span> {`${Boolean(app.applicationFormData.consent)}`}</p>
                                                    <p><span className="font-medium">Started:</span> {app.metadata.applicationStart.split(',')[0]}</p>
                                                    <p><span className="font-medium">Submitted:</span> {app.metadata.applicationSubmit.split(',')[0]}</p>
                                                </div>
                                                </Link>
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                    }
                </SquareTile>
            </div>
            <div className="grid grid-cols-2 gap-20 mt-20">
                <SquareTile styles="overflow-y-scroll">     
                    <div className="w-full border-primary border-bottom border-primary flex justify-between align-center mb-4">
                        <h4 className="text-2xl">Chat</h4>
                    </div>
                    <Messenger clientId={clientId} />
                </SquareTile>
            </div>
        </DashboardPageContainer>
    )
}

export default ClientId;