import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import Button, { BUTTON_THEMES } from "../components/Button";
import Logo from "../assets/logo-color.png";

const DeclinedCookiesPage: FunctionComponent = () => {
  const navigate = useNavigate();

  const handleAcceptCookies = (): void => {
    localStorage.setItem("cookieConsent", "accepted");
    navigate("/"); // Redirect to the homepage or another desired route
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center">
      {/* Header */}
      <header className="flex items-center justify-center mb-10">
        <img 
          src={Logo} 
          alt="ClientVitals Logo" 
          className="w-[180px] sm:w-[220px] lg:w-[260px]"
        />
      </header>

      {/* Content Card */}
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-[500px] text-center">
        <h1 className="text-2xl font-extrabold text-gray-800 mb-4">
          Cookies Are Required
        </h1>
        <p className="text-gray-600 mb-6 leading-relaxed">
          Cookies are essential for a secure and personalized experience. To continue using our website, please accept cookies.
        </p>
        <Button 
          text="Accept Cookies" 
          onClick={handleAcceptCookies} 
          theme={BUTTON_THEMES.PRIMARY} 
          styles="w-full py-3 text-lg"
        />
        <p className="mt-4 text-sm text-gray-500">
          <a href="/privacy-policy" className="underline text-primary hover:text-primary-dark"> {/* link to privacy policy */}
            Learn more about how we use cookies
          </a>
        </p>
      </div>

      {/* Background Accent */}
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-primary/10 via-white to-white -z-10"></div>
    </div>
  );
};

export default DeclinedCookiesPage;
